import React from 'react';

import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";

import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Image from "material-ui-image";
import logo from '../img/logo.png';
import Typography from "@material-ui/core/Typography";
import favicon from "../img/favicon.svg";
import Hidden from "@material-ui/core/Hidden";


//---------------------------------------------------------------------------------------
//---| NavBar
//---------------------------------------------------------------------------------------
class Header extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {};
    }

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { classes } = this.props;

        return (
            <Grid container justify="center" alignItems="center" className={classes.root}>

                {/* ################## HEAD PART ################## */}
                <Helmet>
                    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
                    <link rel="shortcut icon" type="image/x-icon" href={favicon}/>
                    <body className={classes.body}/>
                </Helmet>

                <Grid container item xs={12} md={9} justify="center" alignItems="center">

                    <Grid item xs={false} sm={1} lg/>

                    <Hidden xsDown>
                        <Grid item xs>
                            <Link to="/" className={classes.link}>
                                <Grid container justify="center" alignItems="center" className={classes.blockSubtitles} >
                                    <Typography variant="h5" className={classes.title}>
                                        {t('header.subtitles')}
                                    </Typography>
                                </Grid>
                            </Link >
                        </Grid>
                    </Hidden>

                    <Grid item xs={5} sm>
                        <Image src={logo} aspectRatio={16/9} color={"transparent"} animationDuration={250} alt="Logo"/>
                    </Grid>

                    <Grid container item direction={"column"} xs={6} sm>
                        <Hidden smUp>
                            <Link to="/" className={classes.link}>
                                <Grid container justify="center" alignItems="center" className={classes.blockSubtitles} >
                                    <Typography variant="h5" className={classes.title}>
                                        {t('header.subtitles')}
                                    </Typography>
                                </Grid>
                            </Link>
                        </Hidden>

                        <Link to="/info" className={classes.link}>
                            <Grid container justify="center" alignItems="center" className={classes.blockInformation} >
                                <Typography variant="h5" className={classes.title}>
                                    {t('header.information')}
                                </Typography>
                            </Grid>
                        </Link>
                    </Grid>

                    <Grid item xs={1} lg/>
                </Grid>
            </Grid>
        );
    }
    //------------------------------------------------------------
}

//Style CSS
const styles = (theme) => ({
    body: {
        backgroundColor: theme.palette.background.main,
    },
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
        marginBottom: theme.spacing(3),
    },
    blockSubtitles: {
        cursor: 'pointer',

        color: theme.palette.text.primary,
        background: theme.palette.background.paper,

        borderBottomRightRadius: 15,
        borderTopRightRadius: 15,

        borderRight: `2px dotted ${theme.palette.grey.A100}`,
        borderTop: `2px dotted ${theme.palette.grey.A100}`,
        borderBottom: `2px dotted ${theme.palette.grey.A100}`,

        '&:hover': {
            "& $title": {
                color: theme.palette.primary.light,
            },
            color: theme.palette.primary.light,
            borderRight: `2px dashed ${theme.palette.primary.main}`,
            borderTop: `2px dashed ${theme.palette.primary.main}`,
            borderBottom: `2px dashed ${theme.palette.primary.main}`,
        },
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(3),

            borderRight: 'none',
            borderLeft: `2px dotted ${theme.palette.grey.A100}`,

            borderBottomLeftRadius: 15,
            borderTopLeftRadius: 15,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            '&:hover': {
                borderLeft: `2px dashed ${theme.palette.primary.main}`,
                borderRight: 'none',
            },
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(5),
        },
    },
    blockInformation: {
        cursor: 'pointer',

        marginTop: theme.spacing(1),

        color: theme.palette.text.primary,
        background: theme.palette.background.paper,

        borderBottomRightRadius: 15,
        borderTopRightRadius: 15,

        borderRight: `2px dotted ${theme.palette.grey.A100}`,
        borderTop: `2px dotted ${theme.palette.grey.A100}`,
        borderBottom: `2px dotted ${theme.palette.grey.A100}`,

        '&:hover': {
            "& $title": {
                color: theme.palette.primary.light,
            },
            color: theme.palette.primary.light,
            borderRight: `2px dashed ${theme.palette.primary.main}`,
            borderTop: `2px dashed ${theme.palette.primary.main}`,
            borderBottom: `2px dashed ${theme.palette.primary.main}`,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(3),
            borderLeft: 'none',
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
            '&:hover': {
                borderLeft: 'none',
            },
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(5),
        },
    },
    title: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),

        color: theme.palette.text.primary,
        textAlign: "center",
        textDecoration: 'none',

        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(0.25),
            marginBottom: theme.spacing(0.25),
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    },
    link: {
        textDecoration: 'none',
    },
});

export default withStyles(styles) (withTranslation()(Header));
