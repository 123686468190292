import React from 'react';
import ReactDOM from 'react-dom';

// Navigation between pages without reload
import { BrowserRouter } from 'react-router-dom';

//Localization
import "./i18n/i18n";

//Font responsive and special font
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import 'fontsource-roboto';

//Theme and palette
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import lightBlue from "@material-ui/core/colors/lightBlue";
import lightGreen from "@material-ui/core/colors/lightGreen";

import App from "./components/app";

//---------------------------------------------------------------------------------------
//---| General theme
//---------------------------------------------------------------------------------------


let theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: lightBlue,
        secondary: lightGreen,
        divider: '#2196f3', //'rgba(0,255,255,0.5)',
        background: {
            light: '#202020',
            main: '#202020',
            dark: '#202020',
            paper: '#303030'
        }
    },
    typography: {
        fontSize: 15,
    },
    //contrastThreshold: 3,
    //tonalOffset: 0.2,
});
theme = responsiveFontSizes(theme);

//---------------------------------------------------------------------------------------
//---| Class defines with theme
//---------------------------------------------------------------------------------------

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </BrowserRouter>,

    document.getElementById('app')
);
