import React from "react";

import {fade, withStyles} from "@material-ui/core";
import {langImg, langNames} from "./lang";

import none from "../img/none.svg"

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Slider from "@material-ui/core/Slider";
import red from "@material-ui/core/colors/red";
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------
//---| Known Language choices
//---------------------------------------------------------------------------------------
class Difficulty extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {
            langOriginal: props.langOriginal,
            langKnown: props.langKnown,
            difficulty: 0,
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChoice = this.handleChoice.bind(this);
    }

    //Allow to update the component if only props change
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.langOriginal !== this.props.langOriginal) {
            this.setState({langOriginal: this.props.langOriginal});
        }
        if(prevProps.langKnown !== this.props.langKnown) {
            this.setState({langKnown: this.props.langKnown});
        }
    }

    //------------------------------------------------------------

    //On click on next button
    handleNext(event) {
        this.props.onDifficultyChange(this.state.difficulty);
    }

    handleBack(event) {
        this.props.onDifficultyBack();
    }

    //On click on next button
    handleChoice(event, difficulty) {
        this.setState({
            difficulty: difficulty
        });
    }

    //------------------------------------------------------------

    valuetext(value) {
        return `${value}`;
    }

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { langOriginal, langKnown, difficulty } = this.state;
        const { classes } = this.props;

        const marks = [
            {
                value: 0,
                label: '0',
            },
            {
                value: 25,
                label: '25',
            },
            {
                value: 50,
                label: '50',
            },
            {
                value: 75,
                label: '75',
            },
            {
                value: 100,
                label: '100',
            },
        ];

        return (
            <div className={classes.root}>
                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('difficulty.desc')}
                    </Typography>
                </Grid>

                <br/>

                <Grid container spacing={1} direction={"column"} justify="center" alignItems="center">
                    <Grid container item xs={12} lg={11}>
                        {/* ##### 100% KNOWN 0% LEARNT ##### */}
                        <Grid container item xs={4} lg={2} direction={"column"} className={classes.blockLeft}>
                            <Grid container item xs>
                                <Avatar src={langImg[langKnown]? langImg[langKnown]: langImg["other"]}
                                        alt={langNames[langKnown]? langNames[langKnown]["name"]: langKnown}
                                        className={classes.flagImgCircle}/>
                                <Typography variant="body1" className={classes.indicationLeft}>
                                    100%
                                </Typography>
                            </Grid>
                            <Grid container item xs className={classes.elementFlag}>
                                <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                        alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                        className={classes.flagImgCircle}/>
                                <Typography variant="body1" className={classes.indicationLeft}>
                                    0%
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs/>

                        {/* ##### 100% LEARNT ##### */}
                        <Grid container item xs sm={3} lg={2} direction={"column"} justify="center" alignItems="center">
                            <Typography variant="body1" className={classes.indicationMiddle}>
                                100%
                            </Typography>
                            <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                    alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                    className={classes.flagImgCircle}
                                    />
                        </Grid>

                        <Grid item xs/>

                        {/* ##### 0% KNOWN 100% LEARNT ##### */}
                        <Grid container item xs={4} lg={2} direction={"column"} className={classes.blockRight}>
                            <Grid container item xs justify="flex-end">
                                <Typography variant="body1" className={classes.indicationRight}>
                                    100%
                                </Typography>
                                <Avatar src={none}
                                        alt="No subtitle"
                                        className={classes.flagImgCircle}/>
                            </Grid>
                            <Grid container item xs justify="flex-end" className={classes.elementFlag}>
                                <Typography variant="body1" className={classes.indicationRight}>
                                    0%
                                </Typography>
                                <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                        alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                        className={classes.flagImgCircle}/>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container item xs={11} lg={10}>
                        <Slider
                            value={difficulty}
                            onChange={this.handleChoice}
                            defaultValue={50}
                            getAriaValueText={this.valuetext}
                            aria-labelledby="discrete-slider-custom"
                            min={0}
                            max={100}
                            step={5}
                            valueLabelDisplay="off"
                            marks={marks} />
                    </Grid>

                    <br/>

                    <Grid container item xs={10} sm={6} md={4} lg={3} spacing={1} justify="space-evenly" alignItems="center" className={classes.summary}>
                        {/* ##### % KNOWN ##### */}
                        <Grid container item xs={3} direction={"column"} justify="center" alignItems="center">
                            <Typography variant="body1">
                                {Math.max(0,100 - difficulty * 2) + "%"}
                            </Typography>
                            <Avatar src={langImg[langKnown]? langImg[langKnown]: langImg["other"]}
                                    alt={langNames[langKnown]? langNames[langKnown]["name"]: langKnown}
                                    className={classes.flagImgCircle}/>
                        </Grid>

                        {/* ##### % LEARNT ##### */}
                        <Grid container item xs={3} direction={"column"} justify="center" alignItems="center">
                            <Typography variant="body1" >
                                {
                                    difficulty <= 50 ?
                                        Math.max(0, difficulty * 2 ) + "%"
                                        :
                                        Math.max(0, 100 - ((difficulty - 50) * 2) ) + "%"
                                }
                            </Typography>
                            <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                    alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                    className={classes.flagImgCircle}/>
                        </Grid>

                        {/* ##### % NONE ##### */}
                        <Grid container item xs={3} direction={"column"} justify="center" alignItems="center">
                            <Typography variant="body1">
                                {Math.max(0,(difficulty - 50) * 2) + "%"}
                            </Typography>
                            <Avatar src={none}
                                    alt="No subtitle"
                                    className={classes.flagImgCircle}/>
                        </Grid>
                    </Grid>

                </Grid>

                <br/>

                <Grid container>
                    <Grid container item xs={3}  justify="flex-start" alignItems="center">
                        <Button size="medium" variant="outlined" className={classes.errorBtn} onClick={this.handleBack}>
                            {t('difficulty.back')}
                        </Button>
                    </Grid>

                    <Grid item xs/>

                    <Grid container item xs={3} justify="center" alignItems="center">
                        <Button disabled={difficulty < 0 && difficulty > 100} size="large" variant="outlined" color="secondary" onClick={this.handleNext}>
                            {t('difficulty.next')}
                        </Button>
                    </Grid>

                    <Grid item xs/>
                    <Grid item xs={3}/>
                </Grid>
            </div>
        );
    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    explanation: {
        fontStyle: 'italic',
    },
    errorBtn: {
        border: `1px solid ${fade(red[500], 0.5)}`,
        color: red[500],
        opacity: 0.7,

        "&:hover": {
            border: `1px solid ${red[500]}`,
            backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
            opacity: 0.7,
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
        /*
        "&$disabled": {
            border: `1px solid ${theme.palette.action.disabled}`
        }
        */
    },
    indicationLeft: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginLeft: theme.spacing(1),

        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(13),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    indicationRight: {
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: theme.spacing(1),

        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(13),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    indicationMiddle: {
        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(13),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    blockLeft: {
        paddingLeft: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4),
        },
    },
    blockRight: {
        paddingRight: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(4),
        },
    },
    elementFlag: {
        marginTop: theme.spacing(1),
    },
    flagImgCircle: {
        border: `2px solid ${theme.palette.grey.A400}`,

        width: theme.spacing(4),
        height: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
    },
    summary: {
        marginTop: theme.spacing(1),
        border: `2px dashed ${theme.palette.grey.A700}`,
        borderRadius: 16
        //width: theme.spacing(10),
        //height: theme.spacing(10),
    },
});


export default withStyles(styles) (withTranslation()(Difficulty));
