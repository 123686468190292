import React from "react";

import {fade, withStyles} from "@material-ui/core";
import {langImg, langNames} from "./lang";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Grow from "@material-ui/core/Grow/Grow";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import {withTranslation} from "react-i18next";


//---------------------------------------------------------------------------------------


//---------------------------------------------------------------------------------------
//---| Known Language choices
//---------------------------------------------------------------------------------------
class LangKnown extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);

        //Remove already chosen language
        let tempList = [];
        for (const lang in props.langList){
            if( props.langList[lang] !== props.langOriginal ){
                tempList.push(props.langList[lang]);
            }
        }

        //Set a list of most common used languages in movies with those available in the full list
        let fullShortcuts = ["en", "zh-CN", "fr", "kr", "it", "jp", "de", "es", "hi"];
        let availableShortcuts = [];
        for (const lang in tempList){
            if(fullShortcuts.includes( tempList[lang] )){
                availableShortcuts.push(tempList[lang]);
            }
        }

        this.state = {
            langNb: props.langList.length,
            langAvailable: tempList ? tempList: [],
            langShortcuts: availableShortcuts ? availableShortcuts: [],
            langChoice: ""
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleChoice = this.handleChoice.bind(this);
    }

    //Allow to update the component if only props change
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.langList.length !== this.props.langList.length) {
            this.setState({langNb: this.props.langList.length});
        }
        if(prevProps.langOriginal !== this.props.langOriginal) {
            this.setState({langOriginal: this.props.langOriginal});
        }
    }

    //------------------------------------------------------------

    //On click on next button
    handleNext(event) {
        this.props.onKnownLangChange(this.state.langChoice);
    }

    //On click on next button
    handleChoice(lang) {
        this.setState({
            langChoice: lang
        });
    }

    handleBack(event) {
        this.props.onKnownLangBack();
    }

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { langAvailable, langShortcuts, langChoice } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('nativeLang.desc')}
                    </Typography>
                </Grid>

                <br/>

                {/* Audio Shortcuts for most common languages (EN ZH FR SP JP IT GE KR HI) */}
                <Grid container spacing={1} justify="center" alignItems="center">
                    {
                        langShortcuts.map((value) => (
                        <Grow key={value} in={true} {...({ timeout: 150 + langAvailable.indexOf(value) * 50 })}>
                            <Grid item xs={3} sm={2} md={2} lg={1}>
                                <Paper elevation={3}
                                       className={langChoice === value ? classes.flagPaperOk: classes.flagPaperKo}
                                       onClick={() => this.handleChoice(value)}>
                                    <Grid container spacing={1} direction="column" justify="center" alignItems="center">
                                        <Grid item xs className={classes.flagGrid} >
                                            <Avatar src={langImg[value]? langImg[value]: langImg["other"]}
                                                   alt={langNames[value]? langNames[value]["name"]: value}
                                                   className={classes.flagImgCircle}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography className={classes.flagName} variant="body1">
                                                {langNames[value]? langNames[value]["name"]: value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grow>
                        ))
                    }
                </Grid>

                <br/>
                <br/>

                {/* Regular language selection */}
                <Grid container spacing={1} justify="center" alignItems="center">

                    {langAvailable.map((value) => (
                        <Grow key={value} in={true} {...({ timeout: 150 + langAvailable.indexOf(value) * 50 })}>
                            <Grid item xs={3} sm={2} md={2} lg={1}>
                                <Paper elevation={3}
                                       className={langChoice === value ? classes.flagPaperOk: classes.flagPaperKo}
                                       onClick={() => this.handleChoice(value)}>
                                    <Grid container spacing={1} direction="column" justify="center" alignItems="center">
                                        <Grid item xs className={classes.flagGrid} >
                                            <Avatar src={langImg[value]? langImg[value]: langImg["other"]}
                                                    alt={langNames[value]? langNames[value]["name"]: value}
                                                    className={classes.flagImgCircle}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography className={classes.flagName} variant="body1">
                                                {langNames[value]? langNames[value]["name"]: value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grow>
                    ))}
                </Grid>

                <Grid container justify="center" alignItems="flex-end">
                    <Grid item xs={9}>
                        <Collapse in={langAvailable.length === 0}>
                            <Alert variant="outlined" severity="warning">
                                {t('nativeLang.error')}
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>

                <br/>

                <Grid container>
                    <Grid container item xs={3}  justify="flex-start" alignItems="center">
                        <Button size="medium" variant="outlined" className={classes.errorBtn} onClick={this.handleBack}>
                            {t('nativeLang.back')}
                        </Button>
                    </Grid>
                    <Grid item xs/>
                    <Grid container item xs={3} justify="center" alignItems="center">
                        <Button disabled={langChoice === ""} size="large" variant="outlined" color="secondary" onClick={this.handleNext}>
                            {t('nativeLang.next')}
                        </Button>
                    </Grid>
                    <Grid item xs/>
                    <Grid item xs={3}/>
                </Grid>

            </div>
        );
    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    explanation: {
        fontStyle: 'italic',
    },
    errorBtn: {
        border: `1px solid ${fade(red[500], 0.5)}`,
        color: red[500],
        opacity: 0.7,

        "&:hover": {
            border: `1px solid ${red[500]}`,
            backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
            opacity: 0.7,
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
        /*
        "&$disabled": {
            border: `1px solid ${theme.palette.action.disabled}`
        }
        */
    },
    flagPaperKo: {
        border: '2px solid grey',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: fade(green['A700'], theme.palette.action.hoverOpacity),
        },
    },
    flagPaperOk: {
        border: `3px solid ${theme.palette.success.main}`,
        backgroundColor: 'rgba(50, 170, 50, .4)',
        cursor: 'pointer',
        "&.MuiPaper-root": {
            backgroundColor: 'rgba(50, 170, 50, .4)',
        },
    },
    flagGrid: {
        marginTop: theme.spacing(1),
    },
    flagImgCircle: {
        //width: theme.spacing(10),
        //height: theme.spacing(10),
    },
    flagName: {
        fontSize: theme.typography.pxToRem(11),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(13),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
});

export default withStyles(styles) (withTranslation()(LangKnown));
