import React from "react";

import {withStyles} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

//---------------------------------------------------------------------------------------
//---| Loading model
//---------------------------------------------------------------------------------------
class Loading extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            reason: props.loadingReason ? props.loadingReason : ""
        }

    }
    //------------------------------------------------------------

    render() {
        const { reason } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.loadingBlock}>
                <LinearProgress color="primary"/>
                <LinearProgress color="primary"/>
                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {reason}
                    </Typography>
                </Grid>
            </div>
        );

    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    explanation: {
        fontStyle: 'italic',
    },
    loadingBlock: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
});

export default withStyles(styles)(Loading);