import React from "react";

import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";

import MovieCreationTwoToneIcon from '@material-ui/icons/MovieCreationTwoTone';
import LiveTvTwoToneIcon from '@material-ui/icons/LiveTvTwoTone';
import DoneAllTwoToneIcon from '@material-ui/icons/DoneAllTwoTone';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//---| Search features part
//---------------------------------------------------------------------------------------
class Search extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            typingTimeout: 0,
            year: "",
            type: "all",
            selectedFeature: {},
            features: [],
            isOpen: false,
            isLoading: false,
            error: false
        };

        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }
    //------------------------------------------------------------

    //Query the server to know film liked to this search
    fetchFeatures = (title) => {
        const { type, year } = this.state;

        //Set loading img
        this.setState({
            isLoading: true,
            isOpen: true
        });

        //Query back end
        fetch( process.env.REACT_APP_SERVER_ADDR + '/search_title', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'title': title,
                'type': type,
                'year': Search.isYearOk(year) ? parseInt(year) : 0
            })
        })
        .then(res => res.json())
        .then(response => {

            this.setState({
                features: response,
                isLoading: false,
                error: false,
            });
        })
        .catch(err => {
            console.error(err);
            this.setState({
                error: true,
                isOpen: false,
                isLoading: false,
            });
        });

    };

    //------------------------------------------------------------

    //On user input, start request when user stops for x seconds
    // Help found here: https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
    onInputChange(event, value, reason) {
        if(value.length > 2 && reason === 'input'){

            //When user type, reset the search timer
            if (this.state.typingTimeout) {
                clearTimeout(this.state.typingTimeout);
            }

            //Place of delaying function who will trigger itself only after x seconds
            this.setState({
                typingTimeout: setTimeout(() => {
                    this.fetchFeatures(value);
                }, 1000)
            });
        }
    }

    // When we get special event like option selection or clear
    onChange(event, value, reason){
        if( reason === 'select-option' ) {
            //Store the selected movie
            this.setState({
                selectedFeature: value,
            });
        }
        else if ( reason === 'clear' ) {
            //Remove all
            this.setState({
                selectedFeature: {},
                features: []
            })
        }
    }

    //Type feature search changes
    handleTypeChange(event) {
        this.setState({
            type: event.target.value,
        });
    };

    //Feature year changes
    handleYearChange(event) {
        if(event.target.value === "" || Search.isYearOk(event.target.value)) {
            this.setState({
                year: event.target.value,
            });
        }
    }

    //On click on next button
    handleNext(event) {
        this.props.onFeatureChange(this.state.selectedFeature);
    }
    //------------------------------------------------------------

    //Check if year is ok
    static isYearOk(year){
        return year >>> 0 === parseFloat(year) &&
                !year.includes('.') &&
                year < new Date().getFullYear() + 1;
    }

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { selectedFeature, features, type, year, isLoading, isOpen, error } = this.state;
        const { classes } = this.props;

        const loading = isOpen && features.length === 0 && isLoading;
        const featureIsOk = Object.keys(selectedFeature).length !== 0;

        return (
            <div className={classes.root}>

                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('search.desc')}
                    </Typography>
                </Grid>

                <br/>

                <Grid container spacing={1} justify="center" alignItems="flex-end">
                    <Grid item xs={12} md={8} xl={8}>
                        <Autocomplete
                            id="search-feature"
                            classes={{
                                option: classes.option,
                                listbox: classes.listbox,
                            }}
                            onInputChange={this.onInputChange}  //When we type something
                            onChange={this.onChange}            //When we select something
                            open={isOpen}
                            onOpen={() => {}}
                            onClose={() => {
                                this.setState({isOpen: false})
                            }}
                            //Indicate the "already selected" movie
                            getOptionSelected={
                                (option, value) => option.feature_id === value.feature_id
                            }
                            //Choose what text will be shown in the field
                            getOptionLabel={(option) => option.title + " [" + (option.is_series ?  t('search.optionTV') : t('search.optionMovie')) + " - " + option.year + "]" }
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter' && !isOpen && featureIsOk) {
                                    this.handleNext(ev);
                                }
                            }}
                            //Base of suggestions
                            options={features}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label= {t('search.searchLabel')}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid container item xs={6} sm={3} md={2} xl={2}>
                        <TextField id="standard-year"
                                   value={year}
                                   onChange={this.handleYearChange}
                                   label= {t('search.yearLabel')}
                                   type="search"
                                   defaultValue={undefined}
                                   error={
                                       //https://stackoverflow.com/questions/10834796/validate-that-a-string-is-a-positive-integer
                                       year !== "" && !Search.isYearOk(year)
                                   }
                                   inputProps={{
                                       pattern: "[0-9]*",
                                   }}
                                   variant="outlined" />
                    </Grid>

                    <Grid container item xs={6} sm={3} md={2} xl={2} >
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                labelId="select-type-label"
                                id="select-type"
                                value={type}
                                onChange={this.handleTypeChange}
                                label={t('search.typeLabel')}
                                className={classes.select}>
                                <MenuItem value="all">
                                    <Grid container alignItems="center" >
                                        <DoneAllTwoToneIcon/>
                                        <ListItemText className={classes.typeLabel} primary={t('search.optionAll')}/>
                                    </Grid>
                                </MenuItem>
                                <MenuItem value="movie">
                                    <Grid container alignItems="center">
                                        <MovieCreationTwoToneIcon/>
                                        <ListItemText className={classes.typeLabel} primary={t('search.optionMovie')} />
                                    </Grid>
                                </MenuItem>
                                <MenuItem value="tvshow">
                                    <Grid container alignItems="center">
                                        <LiveTvTwoToneIcon/>
                                        <ListItemText className={classes.typeLabel} primary={t('search.optionTV')} />
                                    </Grid>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <br/>

                <Grid container justify="center" alignItems="flex-end">
                    <Grid item xs={12} md={9}>
                        <Collapse in={error}>
                            <Alert variant="outlined" severity="error"
                                   action={
                                       <IconButton
                                           aria-label="close"
                                           color="inherit"
                                           size="small"
                                           onClick={() => {
                                               this.setState({error: false})
                                           }}>
                                           <CloseIcon fontSize="inherit" />
                                       </IconButton>
                                   }>
                                {t('search.error')}
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>

                <br/>

                <Grid container justify="center" alignItems="center">
                    <Button disabled={!featureIsOk} size="large" variant="outlined" color="secondary" onClick={this.handleNext}>
                        {t('search.next')}
                    </Button>
                </Grid>

            </div>
        );
    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    title: {
        fontSize: theme.typography.pxToRem(11),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(14),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    listbox: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.dark,
    },
    typeLabel: {
        marginLeft: theme.spacing(0.5),
    },
    explanation: {
        fontStyle: 'italic',
    },
    option: {
        //None

        // Hover
        '&[data-focus="true"]': {},
        // Selected
        '&[aria-selected="true"]': {},
    },
    select: {
        padding: theme.spacing(1),
    },
    formControl: {
        //margin: theme.spacing(1),
        //minWidth: "150px",
        width: '100%',
    },
});

export default withStyles(styles) (withTranslation()(Search));
