import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";

import ok_green from '../img/ok_green.png';
import ko_red from '../img/ko_red.png';
import {Box, Tooltip} from "@material-ui/core";
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------
//---| Status component
//---------------------------------------------------------------------------------------
class Status extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            styles: props.styles,
            status: 0,
        };

        this.fetchAvailability();
    }

    //------------------------------------------------------------

    //Query the server to know if all connection and auth are ok
    fetchAvailability = () => {

        //Query back end
        fetch( process.env.REACT_APP_SERVER_ADDR + '/available', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({

            })
        })
        .then(res => res.json())
        .then(response => {
            this.setState({
                status: response.is_ok,
            });
        })
        .catch(err => {
            console.error(err);
            this.setState({
                status: 0,
            });
        });

    };

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { classes } = this.props;
        const { status } = this.state;

        return (
            <Box>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={2}>
                        <Tooltip title={status === 1 ? t('status.ok') : t('status.ko')} placement="right" arrow classes={{tooltip: classes.tooltip}}>
                            <Link href="https://www.opensubtitles.com" target="_blank">
                                <Avatar src={status === 1 ? ok_green: ko_red}
                                       alt={"Test"}
                                       className={classes.statusImg}/>
                            </Link>
                        </Tooltip>
                    </Grid>
                    <Grid item xs/>
                </Grid>
            </Box>
        );
    }
    // "· 2021 SubTitle Learning ·"
    //------------------------------------------------------------
}

//Style CSS
const styles = (theme) => ({
    statusImg: {
        width: '32px',
        height: '32px',
        [theme.breakpoints.down('md')]: {
            width: '24px',
            height: '24px',
        },
    },
    tooltip: {
        fontSize: theme.typography.pxToRem(13),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(15),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(16),
        },
    },

});

export default withStyles(styles) (withTranslation()(Status));
