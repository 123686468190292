import React from 'react';

import {fade, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import ModalImage from "react-modal-image";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

import exImg1 from "../img/infoEx1.png";
import exImg2 from "../img/infoEx2.png";
import exImg3 from "../img/infoEx3.png";
import exImg4 from "../img/infoEx4.png";

import SubtitlesIcon from '@material-ui/icons/Subtitles';
import ExtensionIcon from '@material-ui/icons/Extension';
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------
//---| Information Page
//---------------------------------------------------------------------------------------
class PageInfo extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {};
    }

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container spacing={3} justify="center" alignItems="center">
                    <Grid item xs={12} md={11} lg={10} xl={9}>
                        <Paper className={classes.paper}>

                            <br/>
                            <Typography variant="h3">{t('pageInfo.title')}</Typography>
                            <br/>
                            <Divider variant="middle" />
                            <br/>

                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.whatIsTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {t('pageInfo.whatIsDesc')}
                                    </Typography>
                                </Grid>
                            </Grid>


                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.ExampleTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {t('pageInfo.ExampleDesc')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.importLocallyTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid item xs direction={"column"}>
                                    <Typography variant="body1">

                                        {t('pageInfo.importLocallyDesc1')}
                                        <br/><br/>

                                        <Link href="https://www.videolan.org/index.html" target="_blank">
                                            <Button size="medium" className={classes.vlcBtn}>
                                                VLC Media Player Website
                                            </Button>
                                        </Link>

                                        <br/><br/>
                                        {t('pageInfo.importLocallyDesc2')}
                                        <br/>
                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                            <ModalImage
                                                small={exImg1}
                                                large={exImg1}
                                                hideDownload={true}
                                                hideZoom={true}
                                                alt="Open file with VLC"/>
                                        </Grid>

                                        <br/>
                                        {t('pageInfo.importLocallyDesc3')}
                                        <br/>
                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                            <ModalImage
                                                small={exImg2}
                                                large={exImg2}
                                                hideDownload={true}
                                                hideZoom={true}
                                                alt="Add subtitle menu"/>
                                        </Grid>

                                        <br/>
                                        {t('pageInfo.importLocallyDesc4')}
                                        <br/>
                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                            <ModalImage
                                                small={exImg3}
                                                large={exImg3}
                                                hideDownload={true}
                                                hideZoom={true}
                                                alt="Pick subtitles file"/>
                                        </Grid>

                                        <br/>
                                        {t('pageInfo.importLocallyDesc5')}
                                        <br/>
                                        <Grid item xs={4} sm={3} md={2} lg={1}>
                                            <ModalImage
                                                small={exImg4}
                                                large={exImg4}
                                                hideDownload={true}
                                                hideZoom={true}
                                                alt="Finally"/>
                                        </Grid>
                                    </Typography>
                                </Grid>
                            </Grid>


                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.importStreamTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid item xs>
                                    <Typography variant="body1">

                                        {t('pageInfo.importStreamDesc')}
                                        <br/><br/>

                                        <Link href="https://chrome.google.com/webstore/detail/substital-add-subtitles-t/kkkbiiikppgjdiebcabomlbidfodipjg" target="_blank">
                                            <Button size="medium" className={classes.extensionBtn} startIcon={<ExtensionIcon/>}>
                                                {t('pageInfo.importStreamBtnChrome')}
                                            </Button>
                                        </Link>

                                        <br/>
                                        <br/>

                                        <Link href="https://addons.mozilla.org/fr/firefox/addon/substital" target="_blank">
                                            <Button size="medium" className={classes.extensionBtn} startIcon={<ExtensionIcon/>}>
                                                {t('pageInfo.importStreamBtnFirefox')}
                                            </Button>
                                        </Link>

                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.importTVTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {t('pageInfo.importTVDesc')}
                                    </Typography>
                                </Grid>
                            </Grid>


                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.notSyncTitle')}
                            </Typography>
                            <Grid container direction={"column"} className={classes.bodyBlock}>
                                <Grid item xs>
                                    <Typography variant="body1">
                                        {t('pageInfo.notSyncDesc1')}
                                        <br/>
                                        <Link item href="https://wiki.videolan.org/VLC_HowTo/Adjust_subtitle_delay/" target="_blank">
                                            {t('pageInfo.notSyncDescLink')}
                                        </Link>
                                        <br/><br/>
                                        {t('pageInfo.notSyncDesc2')}
                                        <br/><br/>
                                        {t('pageInfo.notSyncDesc3')}
                                        <br/>
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} sm={3} md={2} lg={1}>
                                    <ModalImage
                                        small={"https://addons.cdn.mozilla.net/user-media/previews/full/190/190049.png?modified=1543520665"}
                                        large={"https://addons.cdn.mozilla.net/user-media/previews/full/190/190049.png?modified=1543520665"}
                                        hideDownload={true}
                                        hideZoom={true}
                                        alt="Substital delay configuration example"/>
                                </Grid>
                            </Grid>


                            {/* ######################################################################## */}
                            <Typography variant="h4" className={classes.titleText}>
                                {t('pageInfo.notAvailableTitle')}
                            </Typography>
                            <Grid container className={classes.bodyBlock}>
                                <Grid container item xs direction={"column"}>
                                    <Typography variant="body1">
                                        {t('pageInfo.notAvailableDesc')}
                                    </Typography>

                                    <Grid container item xs spacing={1}>

                                        <Grid item xs={12} sm={4}>
                                            <Link href="https://yts-subs.com/" target="_blank">
                                                <Button size="medium" className={classes.otherBtn} startIcon={<SubtitlesIcon/>}>
                                                    Yify Subtitles
                                                </Button>
                                            </Link>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Link href="https://subscene.com/" target="_blank">
                                                <Button size="medium" className={classes.otherBtn} startIcon={<SubtitlesIcon/>}>
                                                    Subscene
                                                </Button>
                                            </Link>
                                        </Grid>

                                        <Grid item xs={12} sm={4}>
                                            <Link href="https://www.podnapisi.net/" target="_blank">
                                                <Button size="medium" className={classes.otherBtn} startIcon={<SubtitlesIcon/>}>
                                                    Podnapisi
                                                </Button>
                                            </Link>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>

                            <br/>
                            <Divider variant="middle" />
                            <br/>

                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );

    }


    //------------------------------------------------------------
}

//Style CSS
const styles = (theme) => ({
    root: {
        whiteSpace: "pre-line",  // To break line on \n in json
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
    },

    bodyBlock:{
        padding: theme.spacing(0.5),
        marginLeft: theme.spacing(1),
        borderLeft: `1px dotted ${theme.palette.grey.A100}`,

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1),
            marginLeft: theme.spacing(2),
        },
    },
    titleText:{
        marginTop: theme.spacing(2),
    },
    vlcBtn: {
        border: `2px solid ${fade(orange[500], 0.5)}`,
        color: orange[500],

        "&:hover": {
            border: `2px solid ${orange[500]}`,
            backgroundColor: fade(orange[500], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    extensionBtn: {
        border: `2px solid ${fade(green[200], 0.5)}`,
        color: green[200],

        "&:hover": {
            border: `2px solid ${green[200]}`,
            backgroundColor: fade(green[200], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    otherBtn: {
        margin: theme.spacing(2),
        border: `2px solid ${fade(grey[300], 0.5)}`,
        color: grey[300],

        "&:hover": {
            border: `2px solid ${grey[300]}`,
            backgroundColor: fade(grey[300], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },

});

export default withStyles(styles) (withTranslation()(PageInfo));
