import React from 'react';

import {langNames} from "./lang";

import Search from "./search";
import Episode from "./episode";
import LangOriginal from "./langOriginal"
import LangKnown from "./langKnown"
import Difficulty from "./difficulty"
import Process from "./process"
import Result from "./result"
import Loading from "./loading";

import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper/Paper";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------
//---| Subtitles Page
//---------------------------------------------------------------------------------------
class PageSubtitles extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            expendedPanel: 'panelSearch', //panelSearch
            loading: false, //for available subtitle principally
            error: false, //for available subtitle principally
            feature: {},
            tv_show: false,
            season: 0,
            episode: 0,
            lang_available: {},
            lang_original: "",
            lang_known: "",
            count:0, //Count of for example all en and fr subs (useful for back end to query the server)
            difficulty: -1,
            could_second_try: false,
            is_second_try: false,
            dlLink: ""
        };

        this.scroll_original_lang_ref = React.createRef(); // Used to scroll / https://stackoverflow.com/questions/43441856/how-to-scroll-to-an-element
        this.scroll_known_lang_ref = React.createRef();

        this.handleFeatureChange = this.handleFeatureChange.bind(this);
        this.handleEpisodeChange = this.handleEpisodeChange.bind(this);
        this.handleOriginalLangChange = this.handleOriginalLangChange.bind(this);
        this.handleKnownLangChange = this.handleKnownLangChange.bind(this);
        this.handleDifficultyChange = this.handleDifficultyChange.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);

        this.handleEpisodeBack = this.handleEpisodeBack.bind(this);
        this.handleOriginalLangBack = this.handleOriginalLangBack.bind(this);
        this.handleKnownLangBack = this.handleKnownLangBack.bind(this);
        this.handleDifficultyBack = this.handleDifficultyBack.bind(this);
        this.handleLinkBack = this.handleLinkBack.bind(this);

        this.handleSecondTry = this.handleSecondTry.bind(this);

        this.handleReset = this.handleReset.bind(this);
    }

    //------------------------------------------------------------
    // Handle components callback

    //Search
    handleFeatureChange(feature) {
        this.setState({
            feature: feature,
            lang_available: feature.subs_by_lang,
            tv_show: feature.is_series,
            expendedPanel: feature.is_series ? 'panelEpisode': 'panelLangOriginal',
        });

        this.scroll_original_lang_ref.current.scrollIntoView();
    }

    //Episode
    handleEpisodeChange(season, episode) {
        this.setState({
            season: season,
            episode: episode
        });

        this.fetchEpisode(season, episode);
        this.scroll_original_lang_ref.current.scrollIntoView();
    }

    handleEpisodeBack() {
        this.setState({
            feature: {},
            season: 0,
            episode: 0,
            expendedPanel: 'panelSearch',
        });
    }

    //Original language
    handleOriginalLangChange(originalLang) {
        this.setState({
            lang_original: originalLang,
            expendedPanel: 'panelLangKnown',
        });
        this.scroll_known_lang_ref.current.scrollIntoView();
    }

    handleOriginalLangBack() {
        this.setState({
            lang_original: "",
            lang_list: [],
            error: false,
            feature: this.state.tv_show ? this.state.feature: {},
            expendedPanel: this.state.tv_show ? 'panelEpisode': 'panelSearch',
        });
    }

    //Known language
    handleKnownLangChange(knownLang) {
        //Compute the total subs with the original subs and the known
        const { lang_available, lang_original } = this.state;
        let count = lang_available[lang_original] + lang_available[knownLang];
        let could_second_try = lang_available[lang_original] >= 2 && lang_available[knownLang] >= 2;

        this.setState({
            lang_known: knownLang,
            could_second_try: could_second_try,
            count: count,
            expendedPanel: 'panelDifficulty',
        });
    }

    handleKnownLangBack() {
        this.setState({
            lang_known: "",
            could_second_try: false,
            expendedPanel: 'panelLangOriginal',
        });
        this.scroll_original_lang_ref.current.scrollIntoView();
    }

    //Difficulty
    handleDifficultyChange(difficulty) {
        this.setState({
            difficulty: difficulty,
            expendedPanel: 'panelProcess',
        });
    }

    handleDifficultyBack() {
        this.setState({
            difficulty: -1,
            expendedPanel: 'panelLangKnown',
        });
        this.scroll_known_lang_ref.current.scrollIntoView();
    }

    //Sub file link
    handleLinkChange(link) {
        this.setState({
            dlLink: link,
            expendedPanel: 'panelResult',
        });
    }

    handleLinkBack() {
        this.setState({
            expendedPanel: 'panelDifficulty',
        });
    }

    handleSecondTry() {
        this.setState({
            is_second_try: true,
            could_second_try: false,
            expendedPanel: 'panelProcess',
        });
    }

    handleReset() {
        this.setState({
            expendedPanel: 'panelSearch',
            feature: {},
            tv_show: false,
            season: 0,
            episode: 0,
            lang_available:{},
            lang_original: "",
            lang_known: "",
            count: 0,
            difficulty: -1,
            is_second_try: false,
            could_second_try: false,
            dlLink: ""
        });
    }

    //------------------------------------------------------------

    //Query the server to know film liked to this search
    fetchEpisode = (season, episode) => {
        const { feature } = this.state;

        //Set loading img
        this.setState({
            loading: true,
            expendedPanel: 'panelLangOriginal',
        });

        //Query back end
        fetch( process.env.REACT_APP_SERVER_ADDR + '/search_id', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'feature_id': feature.seasons[season][episode],
            })
        })
        .then(res => res.json())
        .then(response => {
            this.setState({
                feature: response,
                lang_available: response.subs_by_lang,
                loading: false,
            });
        })
        .catch(err => {
            console.error(err);
            this.setState({
                loading: false,
            });
        });

    };

    //------------------------------------------------------------

    //On 'Enter' key pressed with no suggestions
    onKeyDown = (event) =>  {

    };

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { expendedPanel, loading, error, feature, tv_show, season, episode, lang_available, lang_original, lang_known, count, difficulty, could_second_try, is_second_try, dlLink } = this.state;
        const { classes } = this.props;

        let featureIsOk = Object.keys(feature).length !== 0;

        let searchStyle = featureIsOk ? classes.okBlock: classes.koBlock;
        let episodeStyle = season > 0 && episode > 0 ? classes.okBlock: classes.koBlock;
        let langOriginalStyle = lang_original.length >= 2 ? classes.okBlock: classes.koBlock;
        let langKnownStyle = lang_known.length >= 2 ? classes.okBlock: classes.koBlock;
        let difficultyStyle = difficulty >= 0 && difficulty <= 100 ? classes.okBlock: classes.koBlock;
        let summaryStyle = dlLink !== "" ? classes.okBlock: classes.koBlock;

        const zeroPad = (num, places) => String(num).padStart(places, '0');

        return (
            <div className={classes.root}>
                <Grid container spacing={3} justify="center" alignItems="center">

                    <Grid item xs={12} md={11} lg={11} xl={9}>
                        <Paper className={classes.paper}>

                            <br/>
                            <Typography variant="h3">
                                {t('pageSubtitle.title')}
                            </Typography>

                            <br/>
                            <Divider variant="middle" />
                            <br/>

                            {/* ################## SEARCH PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelSearch'}
                                       className={expendedPanel === 'panelSearch' ? classes.curBlock : searchStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelSearch-content"
                                    id="panelSearch-header">
                                    <Typography className={classes.heading}>
                                            {t('search.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {featureIsOk ? feature.title : '-'}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Search onFeatureChange={this.handleFeatureChange}/>
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## EPISODES PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelEpisode'}
                                       className={expendedPanel === 'panelEpisode' ? classes.curBlock : episodeStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelEpisode-content"
                                    id="panelEpisode-header">
                                    <Typography className={classes.heading}>
                                        {t('episode.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {season > 0 && episode > 0 ? "S" + zeroPad(season,2) + " E" + zeroPad(episode,2) : "-"}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        featureIsOk && tv_show ?
                                            <Episode seasonList={feature.seasons}
                                                     onEpisodeChange={this.handleEpisodeChange}
                                                     onEpisodeBack={this.handleEpisodeBack}/>
                                        :
                                            <Loading loadingReason={"Loading episodes information"}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## ORIGINAL LANG PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelLangOriginal'} ref={this.scroll_original_lang_ref}
                                       className={expendedPanel === 'panelLangOriginal' ? classes.curBlock: langOriginalStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelLangOriginal-content"
                                    id="panelLangOriginal-header">
                                    <Typography className={classes.heading}>
                                        {t('audioLang.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>{langNames[lang_original]? langNames[lang_original]["name"]: lang_original}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        !loading ?
                                            <LangOriginal langList={Object.keys(lang_available)}
                                                          onOriginalLangChange={this.handleOriginalLangChange}
                                                          onOriginalLangBack={this.handleOriginalLangBack}
                                                          error={error}/>
                                            :
                                            <Loading loadingReason={t("audioLang.loading")}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## KNOWN LANG PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelLangKnown'} ref={this.scroll_known_lang_ref}
                                       className={expendedPanel === 'panelLangKnown' ? classes.curBlock : langKnownStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelLangKnown-content"
                                    id="panelLangKnown-header">
                                    <Typography className={classes.heading}>
                                        {t('nativeLang.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>{langNames[lang_known]? langNames[lang_known]["name"]: lang_known}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        lang_original !== "" ?
                                            <LangKnown langList={Object.keys(lang_available)}
                                                       langOriginal={lang_original}
                                                       onKnownLangChange={this.handleKnownLangChange}
                                                       onKnownLangBack={this.handleKnownLangBack}/>
                                            :
                                            <Loading loadingReason={"Loading available subtitles information"}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## DIFFICULTY PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelDifficulty'}
                                       className={expendedPanel === 'panelDifficulty' ? classes.curBlock: difficultyStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelDifficulty-content"
                                    id="panelDifficulty-header">
                                    <Typography className={classes.heading}>
                                        {t('difficulty.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>{difficulty >= 0 && difficulty <= 100? difficulty: "-"}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        lang_known !== "" ?
                                            <Difficulty langOriginal={lang_original}
                                                        langKnown={lang_known}
                                                        onDifficultyChange={this.handleDifficultyChange}
                                                        onDifficultyBack={this.handleDifficultyBack}/>
                                            :
                                            <Loading loadingReason={"Loading difficulty ladder"}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## PROCESS PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelProcess'}
                                       className={expendedPanel === 'panelProcess' ? classes.curBlock: summaryStyle}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelProcess-content"
                                    id="panelProcess-header">
                                    <Typography className={classes.heading}>
                                        {t('process.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>-</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        difficulty >= 0 && difficulty <= 100 ?
                                            <Process feature={feature} tv_show={tv_show} season={season} episode={episode} difficulty={difficulty}
                                                     langOriginal={lang_original} langKnown={lang_known}
                                                     count={count} is_second_try={is_second_try}
                                                     onLinkChange={this.handleLinkChange}
                                                     onLinkBack={this.handleLinkBack}/>
                                            :
                                            <Loading loadingReason={"Loading summary view"}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>

                            {/* ################## FINISH PART ################## */}
                            <Accordion expanded={expendedPanel === 'panelResult'}
                                       className={expendedPanel === 'panelResult' ? classes.curBlock: classes.koBlock}
                                       elevation={3}>
                                <AccordionSummary
                                    aria-controls="panelResult-content"
                                    id="panelResult-header">
                                    <Typography className={classes.heading}>
                                        {t('result.title')}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>-</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        /* Waiting the var attribution to show */
                                        dlLink !== "" ?
                                            <Result dlLink={dlLink}
                                                    langOriginal={lang_original}
                                                    langKnown={lang_known}
                                                    is_second_try={is_second_try}
                                                    could_second_try={could_second_try}
                                                    onRetry={this.handleSecondTry}
                                                    onReset={this.handleReset}/>
                                            :
                                            <Loading loadingReason={"Loading subtitle file information..."}/>
                                    }
                                </AccordionDetails>
                            </Accordion>

                            <br/>
                            <Divider variant="middle" />

                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
    //------------------------------------------------------------
}

//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    heading: {
        //fontSize: theme.typography.pxToRem(15),
        flexBasis: '70%',
        flexShrink: 0,
    },
    secondaryHeading: {
        //fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    curBlock: {
        border: `1px solid ${theme.palette.info.main}`,

        //Unwanted line at the top for accordion
        "&.MuiAccordion-root:before": {
            height: 0
        },
        '& .MuiAccordionSummary-root:hover': {
            cursor: 'default',
        },
    },
    okBlock: {
        border: `1px solid ${theme.palette.success.main}`,
        //Unwanted line at the top for accordion
        "&.MuiAccordion-root:before": {
            height: 0
        },
        '& .MuiAccordionSummary-root:hover': {
            cursor: 'default',
        },
    },
    koBlock: {
        border: `1px solid ${theme.palette.grey["400"]}`,
        //Unwanted line at the top for accordion
        "&.MuiAccordion-root:before": {
            height: 0
        },
        '& .MuiAccordionSummary-root:hover': {
            cursor: 'default',
        },
    },
    paper: {
        padding: theme.spacing(2),
        //textAlign: 'center',
    },
    loadingBlock: {
        width: '100%',
        '& > * + *': {
            margin: theme.spacing(2),
        },
    },
});

export default withStyles(styles) (withTranslation()(PageSubtitles));
