import React from "react";

import {fade, withStyles} from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Link as RouterLink} from "react-router-dom";
import Link from '@material-ui/core/Link';

import yellow from "@material-ui/core/colors/yellow";
import LocalCafeIcon from '@material-ui/icons/LocalCafe';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import {withTranslation} from "react-i18next";
import {blue} from "@material-ui/core/colors";


//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//---| Final part with DL link and support part
//---------------------------------------------------------------------------------------
class Result extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            fileID: props.dlLink,
            langOriginal: props.langOriginal,
            langKnown: props.langKnown,
            is_second_try: props.is_second_try,
            could_second_try: props.could_second_try
        };

        this.handleReset = this.handleReset.bind(this);
        this.handleRetry = this.handleRetry.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    //Allow to update the component if only props change
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.could_second_try !== this.props.could_second_try) {
            this.setState({could_second_try: this.props.could_second_try});
        }

        if(prevProps.dlLink !== this.props.dlLink) {
            this.setState({fileID: this.props.dlLink});
        }
    }
    //------------------------------------------------------------

    //On click on buttons
    handleReset(event) {
        this.props.onReset();
    }

    handleRetry(event) {
        this.props.onRetry();
    }

    handleDownload(fileID) {
        this.fetchFile(fileID);
    }

    //Query the server to dl sub files
    fetchFile = (fileID) => {

        //Query back end
        fetch( process.env.REACT_APP_SERVER_ADDR + '/dl', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'file_id': fileID,
            })
        })
            .then(res => res.json())
            .then(response => {
                this.setState({
                    dLink: response,
                    isLoading: false,
                });
            })
            .catch(err => {
                console.error(err);
            });
    };

    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { fileID, is_second_try, could_second_try } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('result.desc')}
                    </Typography>
                </Grid>

                <br/><br/>

                <Grid container justify="center" alignItems="center">
                    <Grid item xs={false} md/>

                    <Grid container item xs={11} sm={5} justify="center" alignItems="center">
                        <Paper elevation={5} className={classes.paper}>
                            <Grid container spacing={1} direction="column" justify="center" alignItems="center" className={classes.block}>

                                <Grid item xs>
                                    <Link href={process.env.REACT_APP_SERVER_ADDR + "/dl/" + fileID } target="_blank" download>
                                        <Button size="large" variant="outlined" color="secondary" className={classes.dlBtn} startIcon={<GetAppIcon/>}>
                                            {t('result.download')}
                                        </Button>
                                    </Link>
                                </Grid>

                                <Grid item xs>
                                    <RouterLink to="/info" className={classes.link}>
                                        <Typography variant="subtitle2" className={classes.link}>
                                            {t('result.howTo')}
                                        </Typography>
                                    </RouterLink>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={false} sm={1}/>

                    <Grid container item xs={11} sm={5} justify="center" alignItems="center" className={classes.blockDonation}>
                        <Paper elevation={5} className={classes.paper}>
                            <Grid container spacing={2} direction="column" justify="center" alignItems="center" className={classes.block}>
                                <Grid item xs>
                                    <Typography variant="h4">
                                        {t('result.donationTitle')}
                                    </Typography>
                                </Grid>

                                <Grid item xs>
                                    <Typography variant="body2">
                                        {t('result.donationDesc')}
                                    </Typography>
                                </Grid>

                                <Grid item xs>
                                    <Link href="https://paypal.me/SadiChevrier" target="_blank">
                                        <Button size="medium" variant="contained" startIcon={<LocalCafeIcon />}>
                                            {t('result.donationBtn')}
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={false} md/>
                </Grid>

                <br/><br/>

                <Grid container justify="center" alignItems="center">

                    <Grid item xs/>

                    {
                        /* Showing or not the retry button */
                        could_second_try && !is_second_try ?
                            <Grid container item xs={12} md m={8} justify="center" alignItems="center" className={classes.blockRetry}>
                                <Button variant="outlined" color="secondary" className={classes.retry}
                                        startIcon={<ReplayIcon/>} onClick={this.handleRetry}>
                                    {t('result.retry')}
                                </Button>
                            </Grid>
                            :
                            null
                    }
                    {
                        /* Same idea because we can't have more than 1 block at a time */
                         could_second_try && !is_second_try ?
                            <br/>
                        :
                            null
                    }

                    <Grid container item xs={12} md justify="center" alignItems="center">
                        <Button variant="outlined"  color="primary" onClick={this.handleReset}>
                            {t('result.restart')}
                        </Button>
                    </Grid>

                    <Grid item xs/>
                </Grid>
            </div>
        );
    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json

    },
    paper: {
        padding: theme.spacing(3),
        border: `1px dotted ${theme.palette.primary.light}`,
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(5),
        },
    },
    explanation: {
        fontStyle: 'italic',
    },
    block: {
        //margin: theme.spacing(2),
    },
    dlBtn: {
        border: `2px solid ${fade(yellow[500], 0.5)}`,
        borderRadius: 48,
        color: yellow[500],

        "&:hover": {
            border: `1px solid ${yellow[500]}`,
            backgroundColor: fade(yellow[500], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    retryBtn: {
        border: `2px solid ${fade(blue[500], 0.5)}`,
        borderRadius: 48,
        color: blue[500],

        "&:hover": {
            border: `1px solid ${blue[500]}`,
            backgroundColor: fade(blue[500], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    blockRetry: {
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
        blockDonation: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    donationBtn: {
        border: `2px solid ${fade(yellow[500], 0.5)}`,
        color: yellow[500],

        "&:hover": {
            border: `1px solid ${yellow[500]}`,
            backgroundColor: fade(yellow[500], theme.palette.action.hoverOpacity),
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    link: {
        textDecoration: 'underline solid lightBlue',
        opacity: 0.9,
        fontStyle: 'italic',
        color: theme.palette.text.primary,
        textAlign: "center",
        '&:hover': {
            color: theme.palette.primary.light,
        },
    },
});

export default withStyles(styles) (withTranslation()(Result));
