import React from "react";

import {fade, withStyles} from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import red from "@material-ui/core/colors/red";
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//---| Season / Episode
//---------------------------------------------------------------------------------------
class Episode extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            seasons: props.seasonList ? props.seasonList : {},
            season: 1,
            episode: 1,
        };

        this.handleEpisodeChange = this.handleEpisodeChange.bind(this);
        this.handleSeasonChange = this.handleSeasonChange.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }
    //------------------------------------------------------------
    componentDidMount() {

    }

    //------------------------------------------------------------


    //------------------------------------------------------------

    handleSeasonChange(event){
        this.setState({
            season: parseInt(event.target.value)
        });
    };

    handleEpisodeChange(event){
        this.setState({
            episode: parseInt(event.target.value)
        });
    };

    //On click on next/back button
    handleNext(event) {
        this.props.onEpisodeChange(this.state.season, this.state.episode);
    }

    handleBack(event) {
        this.setState({
            season: 1,
            episode: 1
        });
        this.props.onEpisodeBack();
    }


    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { seasons, season, episode } = this.state;
        const { classes } = this.props;

        let seasonKeys = Object.keys(seasons);
        let episodesKeys = seasonKeys.length > 0 ? Object.keys(seasons[season]): [];

        return (
            <div className={classes.root}>

                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('episode.desc')}
                    </Typography>
                </Grid>

                <br/>

                <Grid container justify="center" alignItems="center">

                    <Grid item xs/>

                    {/* ################## SEASON SELECTION ################## */}
                    <Grid container item xs={4} spacing={2} direction="column"  justify="center" alignItems="center">

                        <Grid item xs>
                            <Typography variant="h4">
                                {t('episode.season')}
                            </Typography>
                        </Grid>

                        <Grid item xs >
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="select-season-id">Season</InputLabel>
                                <Select
                                    labelId="select-season-label"
                                    id="select-seasons"
                                    value={season}
                                    onChange={this.handleSeasonChange}
                                    label={t('episode.season')}>
                                    {seasonKeys.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} md/>

                    {/* ################## EPISODE SELECTION ################## */}
                    <Grid container item xs={4} spacing={2} direction="column" justify="center" alignItems="center">
                        <Grid item xs>
                            <Typography variant="h4">
                                {t('episode.episode')}
                            </Typography>
                        </Grid>

                        <Grid item xs>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="select-episode-id">Episode</InputLabel>
                                <Select
                                    labelId="select-episode-label"
                                    id="select-episode"
                                    value={episode}
                                    onChange={this.handleEpisodeChange}
                                    label={t('episode.episode')}>
                                    {episodesKeys.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid item xs/>
                </Grid>

                <br/>

                <Grid container>
                    <Grid container item xs={3}  justify="flex-start" alignItems="center">
                        <Button size="medium" variant="outlined" className={classes.errorBtn} onClick={this.handleBack}>
                            {t('episode.back')}
                        </Button>
                    </Grid>

                    <Grid item xs/>

                    <Grid container item xs={3} justify="center" alignItems="center">
                        <Button disabled={season === undefined || episode === undefined} size="large" variant="outlined" color="secondary" onClick={this.handleNext}>
                            {t('episode.next')}
                        </Button>
                    </Grid>

                    <Grid item xs/>
                    <Grid item xs={3}/>
                </Grid>

            </div>
        );

    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    explanation: {
        fontStyle: 'italic',
    },
    errorBtn: {
        border: `1px solid ${fade(red[500], 0.5)}`,
        color: red[500],
        opacity: 0.7,

        "&:hover": {
            border: `1px solid ${red[500]}`,
            backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
            opacity: 0.7,
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
        /*
        Generate a warning
        "&$disabled": {
            border: `1px solid ${theme.palette.action.disabled}`
        }
        */
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
    },

});

export default withStyles(styles) (withTranslation()(Episode));
