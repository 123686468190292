import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import Link from "@material-ui/core/Link";

import Status from "./status";

//---------------------------------------------------------------------------------------
//---| NavBar
//---------------------------------------------------------------------------------------
class Footer extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);
        this.state = {
            styles: props.styles,
        };
    }

    //------------------------------------------------------------

    render() {

        const { classes } = this.props;

        return (
            <Grid container justify="center" alignItems="center" className={classes.root}>
                <Grid item  xs={12} md={11} lg={10} xl={9}>
                    <Paper className={classes.paper} >
                        <Grid container justify="center" alignItems="center">

                            <Grid container item justify="center" alignItems="center" xs={2} sm={3}>
                                <Grid item xs={1}/>

                                <Status />

                                <Grid item xs/>
                            </Grid>

                            <Grid item xs/>

                            <Grid item xs={8} sm={5}>
                                <Typography variant="h6" className={classes.brand}>
                                    {"· " + new Date().getFullYear() + " Subtitles Learning ·"}
                                </Typography>
                            </Grid>

                            <Grid item xs/>

                            <Grid container item xs={2} sm={3}>
                                <Grid item xs/>

                                <Link href="mailto:contact@jignefsoft.com?subject=[Subtitle Learning] Hello..." target="_blank">
                                    <IconButton aria-label="Send email" size="small">
                                        <MailOutlineTwoToneIcon />
                                    </IconButton>
                                </Link>

                                <Grid item xs={1}/>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
    // "· 2021 SubTitle Learning ·"
    //------------------------------------------------------------
}

//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(0.5),
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1),
        },
    },
    brand: {
        fontSize: theme.typography.pxToRem(15),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(18),
        },
    },
});

export default withStyles(styles)(Footer);
