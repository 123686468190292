import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import stl_fr from "./fr.json";
import stl_en from "./en.json";

const resources = {
    en: {
        translation: stl_en
    },
    fr: {
        translation: stl_fr
    },
};

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        load: 'languageOnly',
        //lng: 'en',
        fallbackLng: 'en',
        supportedLngs: ["fr", "en"],
        //debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18next;