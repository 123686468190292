import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PageSubtitles from './pageSubtitles';
import PageInfo from './pageInfo';

//https://stackoverflow.com/questions/41956465/how-to-create-multiple-page-app-using-react
const Main = () => {
    return (
        <Switch>
            <Route exact path='/' component={PageSubtitles}/>
            <Route exact path='/info' component={PageInfo}/>
        </Switch>
    );
};

export default Main;