import React from "react";

import {fade, withStyles} from "@material-ui/core";
import {langImg, langNames} from "./lang";

import none from "../img/none.svg"

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Image from "material-ui-image/lib/components/Image/Image";
import red from "@material-ui/core/colors/red";

import Loading from "./loading";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert";
import Hidden from "@material-ui/core/Hidden";
import {withTranslation} from "react-i18next";

//---------------------------------------------------------------------------------------

//---------------------------------------------------------------------------------------
//---| Summary and confirmation before sub process
//---------------------------------------------------------------------------------------
class Process extends React.Component {

    //------------------------------------------------------------
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            feature: props.feature,
            tv_show: props.tv_show,
            season: props.season,
            episode: props.episode,

            langOriginal: props.langOriginal,
            langKnown: props.langKnown,
            count: props.count,
            is_second_try: props.is_second_try,
            difficulty: props.difficulty,
            dLink: "",
            isLoading: false,
        };

        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    //Allow to update the component if only props change
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.is_second_try !== this.props.is_second_try) {
            this.setState({is_second_try: this.props.is_second_try},
                    this.handleNext
                );
        }

        //If retry, we don't want the user to click on the generate button
        if(prevProps.difficulty !== this.props.difficulty) {
            this.setState({difficulty: this.props.difficulty});
        }

    }

    //------------------------------------------------------------

    //On click on next button
    handleNext(event) {
        this.setState({
            isLoading: true,
            error: false,
        });
        this.fetchFileLink();
        //this.props.onLinkChange(this.state.dLink);
    }

    handleBack(event) {
        this.props.onLinkBack();
    }

    //Query the server to convert subtitles and get the file ID as response
    fetchFileLink = () => {

        const { feature, langOriginal, langKnown, difficulty, count, is_second_try } = this.state;

        //Query back end
        fetch( process.env.REACT_APP_SERVER_ADDR + '/process', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                'feature_id': feature.feature_id,
                'lang_original': langOriginal,
                'lang_known': langKnown,
                'title': feature.title,
                'difficulty': difficulty,
                'count': count,
                'second_try': is_second_try,
            })
        })
            .then(res => res.json())
            .then(response => {
                this.setState({
                    dLink: response,
                    isLoading: false,
                });
                this.props.onLinkChange(response);
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    error: true,
                });
                console.error(err);
            });
    };


    //------------------------------------------------------------

    render() {
        const { t } = this.props;
        const { feature, tv_show, season, episode, langOriginal, langKnown, difficulty, isLoading, error } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>

                <Divider variant="middle" />
                <br/>

                <Grid container justify="center" alignItems="center">
                    <Typography variant="body1" className={classes.explanation}>
                        {t('process.desc')}
                    </Typography>
                </Grid>

                <br/>

                <Grid container spacing={1} direction={"column"} justify="center" alignItems="center">

                    <Hidden smDown>
                        <Grid container item xs md={11} lg={10} spacing={2} className={classes.summary}>
                            <Grid container item xs={1} justify="center" alignItems="center">
                                <Grid item xs>
                                    <Image src={feature.img_url} color={'transparent'} className={classes.featureImg} alt="Movie Image"/>
                                </Grid>
                            </Grid>

                            <Grid container item xs justify="center" alignItems="center" className={classes.blockMarginLeft}>
                                <Typography variant="h5">
                                    {feature.title}
                                </Typography>
                            </Grid>

                            {
                                tv_show ?
                                <Grid container item xs={2} justify="center" alignItems="center" className={classes.blockMarginLeft}>
                                    <Typography variant="h6">
                                        { t('process.season') + season }
                                        <br/>
                                        { t('process.episode') + episode }
                                    </Typography>
                                </Grid>
                                    : null
                            }

                            <Grid container item xs={3} justify="center" alignItems="center" className={classes.blockMarginLeft}>
                                {/* ##### % KNOWN ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={langImg[langKnown]? langImg[langKnown]: langImg["other"]}
                                            alt={langNames[langKnown]? langNames[langKnown]["name"]: langKnown}
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {Math.max(0,100 - difficulty * 2) + "%"}
                                    </Typography>
                                </Grid>

                                {/* ##### % LEARNT ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                            alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {
                                            difficulty <= 50 ?
                                                Math.max(0, difficulty * 2 ) + "%"
                                                :
                                                Math.max(0, 100 - ((difficulty - 50) * 2) ) + "%"
                                        }
                                    </Typography>
                                </Grid>

                                {/* ##### % NONE ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={none}
                                            alt="No subtitle"
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {Math.max(0,(difficulty - 50) * 2) + "%"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Hidden mdUp>
                        <Grid container item xs={12} spacing={2} direction={"column"} className={classes.summary}>
                            <Grid container item xs>
                                <Grid container item xs={2} justify="center" alignItems="center">
                                    <Grid item xs>
                                        <Image src={feature.img_url} color={'transparent'} className={classes.featureImg} alt="Movie Image"/>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={10} direction={"column"} justify="center" alignItems="center">
                                    <Typography variant="h4">
                                        {feature.title}
                                    </Typography>

                                    {
                                        tv_show ?
                                            <Typography variant="body2">
                                                { t('process.season') + " " + season + " - " + t('process.episode') + " " + episode }
                                            </Typography>
                                        : null
                                    }
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} justify="center" alignItems="center" className={classes.blockMarginBottom}>
                                {/* ##### % KNOWN ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={langImg[langKnown]? langImg[langKnown]: langImg["other"]}
                                            alt={langNames[langKnown]? langNames[langKnown]["name"]: langKnown}
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {Math.max(0,100 - difficulty * 2) + "%"}
                                    </Typography>
                                </Grid>

                                {/* ##### % LEARNT ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={langImg[langOriginal]? langImg[langOriginal]: langImg["other"]}
                                            alt={langNames[langOriginal]? langNames[langOriginal]["name"]: langOriginal}
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {
                                            difficulty <= 50 ?
                                                Math.max(0, difficulty * 2 ) + "%"
                                                :
                                                Math.max(0, 100 - ((difficulty - 50) * 2) ) + "%"
                                        }
                                    </Typography>
                                </Grid>

                                {/* ##### % NONE ##### */}
                                <Grid container item xs direction={"column"} justify="center" alignItems="center">
                                    <Avatar src={none}
                                            alt="No subtitle"
                                            className={classes.flagImgCircle}/>
                                    <Typography variant="body1" className={classes.flagPercent}>
                                        {Math.max(0,(difficulty - 50) * 2) + "%"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>

                <br/> <br/>

                {/* ############# LOADING ############# */}
                { isLoading ? <Loading loadingReason={t('process.loading')}/> : null }

                {/* ############# ERROR ############# */}
                <Grid container justify="center" alignItems="flex-end">
                    <Grid item xs={12} md={9}>
                        <Collapse in={error}>
                            <Alert variant="outlined" severity="error">
                                {t('process.error')}
                            </Alert>
                        </Collapse>
                    </Grid>
                </Grid>

                <br/>

                <Grid container>
                    <Grid container item xs={3}  justify="flex-start" alignItems="center">
                        <Button size="medium" variant="outlined" disabled={isLoading} className={classes.errorBtn} onClick={this.handleBack}>
                            {t('process.back')}
                        </Button>
                    </Grid>

                    <Grid item xs={1}/>

                    <Grid container item xs justify="center" alignItems="center">
                        <Button variant="outlined" disabled={isLoading} color="secondary" size="large" className={classes.marginButton} onClick={this.handleNext}>
                            {t('process.next')}
                        </Button>
                    </Grid>

                    <Grid item xs={1}/>
                    <Grid item xs={3}/>
                </Grid>

            </div>
        );
    }
    //------------------------------------------------------------
}
//---------------------------------------------------------------------------------------
//Style CSS
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        whiteSpace: "pre-line",  // To break line on \n in json
    },
    summary: {
        marginTop: theme.spacing(1),
        border: `1px solid ${theme.palette.grey.A700}`,
        borderRadius: 16,

        [theme.breakpoints.up('md')]: {
            borderRadius: 48,
            border: `2px solid ${theme.palette.grey.A700}`,
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    explanation: {
        fontStyle: 'italic',
    },
    errorBtn: {
        border: `1px solid ${fade(red[500], 0.5)}`,
        color: red[500],
        opacity: 0.7,

        "&:hover": {
            border: `1px solid ${red[500]}`,
            backgroundColor: fade(red[500], theme.palette.action.hoverOpacity),
            opacity: 0.7,
            "@media (hover: none)": {
                backgroundColor: "transparent"
            }
        },
    },
    blockMarginLeft: {
        borderLeft: `2px solid ${theme.palette.grey.A700}`,
    },
    blockMarginBottom: {
        borderTop: `1px solid ${theme.palette.grey.A700}`,
    },
    elementFlag: {
        marginTop: theme.spacing(1),
    },
    flagImgCircle: {
        border: `2px solid ${theme.palette.grey.A400}`,

        width: theme.spacing(4),
        height: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
    },
    flagPercent: {
        marginTop: theme.spacing(1),
        fontSize: theme.typography.pxToRem(12),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(13),
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(15),
        },
    },
    featureImg: {
        //border: `2px solid ${theme.palette.grey.A200}`,
        marginTop: 'auto',
        marginBottom: 'auto',
        borderRadius: '50%',
    },
    marginButton: {
        margin: theme.spacing(1),
    },
});


export default withStyles(styles) (withTranslation()(Process));
